
import Vue from "vue";

export default Vue.extend({
  name: "MentorcastPhotoGalleryModal",
  data() {
    return {
      activePicture: -1,
      pictures: [] as any
    };
  },
  created() {
    this.$store.dispatch("mentorcast/GET_MENTORCAST_GALLERY").then(data => {
      this.pictures = data;
    });
  },
  methods: {
    onSubmit() {
      if (this.activePicture > -1) {
        const picture = this.pictures[this.activePicture];
        this.$emit("image", picture.file_link);
      }
    }
  }
});
